p.q-content {
  white-space: pre-line;
}
div.blank > input {
  padding: 0px !important;
  font-size: 0.875rem !important;
  text-align: center;
}
.reading {
  background-color: #ba68c8;
}
span.questions {
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em;
}
.grid-content{
  overflow-y: auto;
  max-width: 450px;
}
.custom-snackbar-2 {
  min-width: 0 !important;
  text-align: center;
  padding-left: 0;
}
.custom-snackbar-2 > div:first-child{
  width:0px;
  display: none;
}
.custom-snackbar-2 > div:nth-child(2){
  padding-left: 0px;
}
.custom-snackbar-1{
  min-width: 0px!important;
}
img{
  max-width: 100%;
}
.answer-radio > span:first-child{
  height: auto;
}
h6{
  font-size: 1.15rem !important;
}
.textfield-answer{
  border: 1px solid #BDBDBD !important;
  border-radius: 5px;
  size: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.46429em;
  overflow: hidden;
  resize: none;
  padding: 5px !important;
  width:98%;
  height:92%;
}
.chkbox-answer > span:first-child{
  height: auto;
}
.registration-form{
  padding-top: 10px;
}
.form-have-taken > span:nth-child(2){
  font-size:18.4px;
  height: auto;
}
.form-have-taken > span:first-child{
  height: auto;
}
span.questions-listening{
  padding-top:20px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em;
}
p.q-content-finish{
  white-space: pre-line;
  font-size: 1.0rem;
}
.other-target-country > div > input{
  padding: 0px;
  text-align: center;
}
.text-center{
  text-align: center;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.under-title{
  font-size: 1.0rem !important;
}

.black-box{
  border: 1px solid rgba(0, 0, 0, 0.54);
  padding: 10px;
}

.box-padding10{
  padding: 10px;
}

ul.no-indent{
  padding-left   : 5px;
  padding-bottom : 5px;
  padding-right  : 10px;
}

tbody.table2{
  border: 1px solid rgba(0, 0, 0, 0.54);
}

tbody.table2 > tr > td {
  border: .5px solid rgba(224, 224, 224, 1);
}

thead.table2 > tr > td {
  border: 1px solid rgba(224, 224, 224, 1);
}

div.center-div{
  text-align: left;
  width: 60%;
  margin: auto;
}

.text-left{
  text-align: left;
}

div.center-box{
  padding: 16px;
  margin:auto;
  border: 1px  solid rgba(224, 224, 224, 1);
}

div.center-box-small{
  padding: 16px;
  margin:auto;
  border: 1px  solid rgba(224, 224, 224, 1);
  width: 70%;
}

div.center-box-vsmall{
  padding: 16px;
  margin:auto;
  border: 1px  solid rgba(224, 224, 224, 1);
  width: 50%;
}

div.center-box-vxsmall{
  padding: 16px;
  margin:auto;
  border: 1px  solid rgba(224, 224, 224, 1);
  width: 30%;
}

.text-right{
  text-align: right;
}

.center-box-loading{
  padding: 16px;
  margin:auto;
  border: 20px  solid rgb(33, 150, 243);
}

.invisible {
  visibility: hidden;
}

.sound-wave {
  width: 40%;
  border: 1px  solid #000000;
}

.padding-speaking {
  padding-top:8px;
}

.snackbar-color{
  color: #2196f3 !important;
}

div.snackbar-middle > div > div:nth-child(2) {
  padding-left: 0px;
}

div.snackbar-btn {
  top: 10px;
  left: auto;
  right: 10px;
  z-index: 1400;
  display: flex;
  position: fixed;
  align-items: center;
}

div.snackbar-info {
  top: 7%;
  left: auto;
  right: 10px;
  z-index: 1400;
  display: flex;
  position: fixed;
  align-items: center;
  line-height: 18.2px!important;
  max-width: 25%;
}

div.snackbar-btn-skip {
  top: 7%;
  left: auto;
  right: 10px;
  z-index: 1400;
  display: flex;
  position: fixed;
  align-items: center;
}

div.left-examtype {
  top: 10px;
  left: 10px;
  right: auto;
  z-index: 1400;
  display: flex;
  position: fixed;
  align-items: center;
}

button.full-width{
  width: 100%;
}

div.eighty-percent {
  text-align: left;
  width: 80%;
  margin: auto;
}

div.requirement-check {
  border: solid 1px;
  background-color: #43a047;
  border-color: #43a047;
  width: 100%;
  padding: 8px;
  text-align: left;
}

div.requirement-missing {
  background-color: #d32f2f;
  border-color: #d32f2f;
  color: white;
  width: 100%;
  padding: 8px;
  text-align: left;
}

.icon {
  text-align:right;
  vertical-align: middle;
  color: #ffffff;
}

button.little-margin{
  margin: 10px;
}

div.center-box-medium{
  text-align: left;
  padding: 16px;
  margin:auto;
  border: 1px  solid rgba(224, 224, 224, 1);
  width: 90%;
}

hr.speaking{
  height:2px;
  background-color: rgba(224, 224, 224, 1);
}

div.bordered {
  border: 1px solid #BDBDBD !important;
  padding: 16px;
}
.width-60{
  width:60%;
}

.text-white{
  color:white!important;
  font-size: 0.875rem!important;
  font-weight: 500!important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
}

.icon-wrong {
  text-align:right;
  vertical-align: middle;
  color: #ffffff;
}